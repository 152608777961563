<template>
  <div class="verify-auth"></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  created() {
    this.getAuthentication()
  },
  methods: {
    ...mapActions({
      getAuthentication: 'backoffice/getAuthentication',
    }),
  },
}
</script>
