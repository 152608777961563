import { render, staticRenderFns } from "./VerifyAuth.vue?vue&type=template&id=fbe72340"
import script from "./VerifyAuth.vue?vue&type=script&lang=js"
export * from "./VerifyAuth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports